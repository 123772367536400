<template>
    <about-subpage :charName="'episodes'">
        <template v-slot:content>
            <p class="mb-2">Character designs for</p>
            <h1 class="title">Episode 1</h1>
            <img @click="open" class="open" src="@/assets/img/episode-1/Arbiter-Dorel.jpg" />
            <img @click="open" class="open" src="@/assets/img/episode-1/Tane.jpg" />
            <img @click="open" class="open" src="@/assets/img/episode-1/Mrs-Wyton.jpg" />
            <img @click="open" class="open" src="@/assets/img/episode-1/Othed.jpg" />
            <img @click="open" class="open" src="@/assets/img/episode-1/Wina.jpg" />
            <h2 class="subtitle mt-5">Older pre-production art</h2>
            <p>
                Early city design from the short story "Lost Things" in the
                <a href="https://www.spiderforest.com/" target="_blank">
                    SpiderForest
                </a>
                "Threads: A Gallery of Rogues" comic anthology
            </p>
            <img @click="open" class="open" src="@/assets/img/episode-1/rogues-antholoty-ltc-city.jpg" />
        </template>
    </about-subpage>
</template>

<script>
import AboutSubpage from "@/components/AboutSubpage.vue";

export default {
    name: "Episode1",
    components: { AboutSubpage },
    methods: {
        open(event) {
            window.open(event.target.src, '_blank')
        },
    }
};
</script>
